/// <reference path="../../../typings/libraries.d.ts"/>
(function() {

	interface IPromo {
		contentId: string;
		excerptData: string;
		contentName: string;
		contentUrl: string;
	}

	function loadPromo() {
		return new Promise<IPromo>((resolve) => {
			$.get('/API/Content/GetCurrentPromotion', function(data) {
				data.contentUrl = '/Content/' + data.contentId;
				resolve(data as IPromo);
			}, 'json');
		});
	}

	function refreshPromoUI(promo: IPromo) {
		const promoContainerElement = document.getElementById('promo');
		if(!promoContainerElement || promoContainerElement.hasAttribute('data-custom-promo')) { return; }

		Alkami.Dom.hideElement(promoContainerElement);
		promoContainerElement.innerHTML = '';

		if (promo.excerptData) {
			Alkami.Dom.showElement(promoContainerElement);
			promoContainerElement.innerHTML = `<a href="${promo.contentUrl}" title="${promo.contentName}" aria-label="${promo.contentName}">${promo.excerptData}</a>`;
		}
	}

	Alkami.Dom.onDocumentReady(() => {
        const promoContainerElement = document.getElementById('promo');
        if (!promoContainerElement || promoContainerElement.hasAttribute('data-custom-promo') || RegExp('logout', 'i').test(document.URL)) { return; }

		Alkami.Dom.hideElement(promoContainerElement);

		loadPromo().then((promo) => {
			refreshPromoUI(promo);
			return promo;
		});
	});
})();
